<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card>
      <v-card-title class="mb-3">Auto Texto
      </v-card-title>
      <v-card-text>
        <v-text-field dense filled rounded label="descricao" autofocus autocomplete="off"
          v-model="autoTextoModel.descricao"></v-text-field>
        <Editor title="conteudo" :titleEditor="false" v-model="autoTextoModel.conteudo"></Editor>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="close">Cancelar</v-btn>
        <v-btn color="primary" @click="salvar">Gravar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import Editor from "@/components/comum/Editor/Simple.vue";
import { mapActions } from "vuex";

export default {
  name: "auto-texto-dialog",
  props: {
    display: { Type: Boolean, default: false },
    autoTexto: null
  },
  watch: {
    display(value) {
      this.dialog = value
    },
    autoTexto(value) {
      if (value) {
        this.autoTextoModel.descricao = value.descricao
        this.autoTextoModel.conteudo = value.conteudo
      }
    }
  },
  data() {
    return {
      dialog: false,
      autoTextoModel: {}
    };
  },
  components: {
    Editor
  },
  methods: {
    ...mapActions(["setAlerta"]),    
    editar() {// event
      // this.$emit('editar', this.node.attrs);
      // console.log("editar", event, this.node.attrs);

      // this.opcoesTmp = this.node.attrs.opcoes.split('|').map(item => ({ "value": item.trim() }));
      this.dialog = true
    },
    salvar() {      
      console.log("Gravando " , this.autoTextoModel);      
      
      if (this.autoTextoModel.descricao.trim() == "" || this.autoTextoModel.conteudo.trim() == "") {
        this.setAlerta({
          tipoAlerta: "error",
          textAlerta: "Favor preencher todos os campos obrigatórios",
        });
        return;
      }
      
      const self = this;

      const data = {
        atalho: self.autoTextoModel.descricao,
        conteudo: self.autoTextoModel.conteudo
      }  
      self.$http
        .post("/autoTexto", data)
        .then(() => {//response          
          self.setAlerta({
            tipoAlerta: "success",
            textAlerta: "Auto Texto criado com sucesso!",
          });
          this.close();
        })
        .catch((error) => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: error.message
          });
        });

    },
    close() {
      this.dialog = false
      this.$emit('update:display', false);
    }
  },
};
</script>

<style lang="scss"></style>
