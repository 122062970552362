var recognition;
function create() {
  window.SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;
  recognition = new window.SpeechRecognition();
  recognition.interimResults = false;
  // recognition.lang = "en-US";
  recognition.lang = "pt-BR";
  recognition.continuous = true;
  return recognition;
}

function start(onresult, onend) {
  console.log("start");
  recognition.start();
  recognition.onresult = (event) => {
    for (let i = event.resultIndex; i < event.results.length; i++) {
      if (event.results[i].isFinal) {
        // Here you can get the string of what you told
        const content = tratarTexto(event.results[i][0].transcript.trim());
        // console.log(content);
        onresult(content);
      }
    }
  };
  recognition.onerror = (erro) => {
    console.log(erro);
  }
  recognition.onend = () => {
    console.log("Speech recognition service disconnected");
    onend();
  };
}

function tratarTexto(texto) {
  let resultato = texto;
  resultato = resultato.replace(/ponto e vírgula/gi, ";");
  resultato = resultato.replace(/dois pontos/gi, ":");
  resultato = resultato.replace(/espaço/gi, " ");
  resultato = resultato.replace(/vírgula/gi, ",");
  resultato = resultato.replace(/exclamação/gi, "!");
  resultato = resultato.replace(/interrogação/gi, "?");
  resultato = resultato.replace(/ponto/gi, ".");
  resultato = resultato.replace(/quebra a linha/gi, "<p>");
  resultato = resultato.replace(/quebra linha/gi, "<p>");
  resultato = resultato.replace(/quebra de linha/gi, "<p>");
  resultato = resultato.replace(/próxima linha/gi, "<p>");
  resultato = resultato.replace(/próxima à linha/gi, "<p>");
  resultato = resultato.replace(/próximo linha/gi, "<p>");
  resultato = resultato.replace(/próximo à linha/gi, "<p>");
  
  resultato = resultato.replace(/ \./gi, ".");
  
  return resultato;
}

function stop() {
  recognition.stop();
}


function falar(textoFala) {
  let timer = 0;
  if (voz == null) {
    loopVoz();
    timer = 2000;    
  }

  setTimeout(function () {
    if (voz != null && voz != "-") {
      speak(textoFala);
    }
  }, timer);
}

var voz;
function loadVoz() {
  for (var i = 0; i <= speechSynthesis.getVoices().length; i++) {
    if (speechSynthesis.getVoices()[i]) {
      //console.log('a  '+  speechSynthesis.getVoices()[i].name);
      // if (pVoz) {
      //   if (speechSynthesis.getVoices()[i].name == pVoz) {
      //     voz = speechSynthesis.getVoices()[i];
      //     return;
      //   }
      // } else 
      if (
        speechSynthesis.getVoices()[i].name == "Raquel" ||
        speechSynthesis.getVoices()[i].name == "Google português do Brasil" ||
        speechSynthesis.getVoices()[i].lang == "pt-BR" ||
        speechSynthesis.getVoices()[i].name == "native" ||
        (window.navigator.userAgent.indexOf("Mac") != -1 &&
          speechSynthesis.getVoices()[i].name == "Luciana")
      ) {
        voz = speechSynthesis.getVoices()[i];
        console.log("ACHOU!!!!!!", voz.name);
        return;
      }
    }
  }
}

function loopVoz() {
  setTimeout(function () {
    loadVoz();
    if (voz == null) {
      loopVoz();
    }
  }, 500);
}

function speak(text) {
  // Create a new instance of SpeechSynthesisUtterance.
  var msg = new SpeechSynthesisUtterance();

  // Set the text.
  msg.text = text;

  // Set the attributes.
  msg.volume = 1.0; //parseFloat(volumeInput.value);
  msg.rate = 1.0; //parseFloat(rateInput.value);
  msg.pitch = 1.0; //parseFloat(pitchInput.value);

  // If a voice has been selected, find the voice and set the
  // utterance instance's voice attribute.

  msg.voice = voz; //speechSynthesis.getVoices().filter(function(voice) { return voice.name == 'native'; })[0];
  //msg.voice = speechSynthesis.getVoices().filter(function(voice) { return voice.name == 'native'; })[0];

  // Queue this utterance.
  window.speechSynthesis.speak(msg);
}


module.exports = {
  create,
  start,
  stop,
  falar
};
